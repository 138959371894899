import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layout/Layout';
import PrivateRoute from '../components/PrivateRoute';
import Login from '../components/login/Login';
import Register from '../components/register/Register';
import Collections from '../components/collections/Collections';
import Account from '../components/account/Account';
import { OrderContextProvider } from '../context/OrderContext';
import ResetPassword from '../components/resetPassword/ResetPassword';
import NewPasswordForm from '../components/resetPassword/newPasswordForm/NewPasswordForm';
import RegisterConfirmation from '../components/register/registerConfirmation/RegisterConfirmation';
import PatientOrders from '../components/account/doctorAccount/patients/pages/orders/PatientOrders';
import Order from '../components/account/doctorAccount/orders/order/Order';
import PatientSubscription from '../components/account/doctorAccount/patients/pages/subscription/PatientSubscription';
import PatientInfo from '../components/account/doctorAccount/patients/pages/info/PatientInfo';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PatientContextProvider } from '../context/PatientContext';

const Customer = ({ location }) => {
  const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PK_KEY}`);

  return (
    <OrderContextProvider>
      <PatientContextProvider>
        <Layout location={location}>
          <Elements stripe={stripePromise}>
            <Router basepath="/customer">
              <PrivateRoute path="/account" component={Account} />
              <PrivateRoute path="/collections" component={Collections} />
              <PrivateRoute path="/account/orders" component={PatientOrders} />
              <PrivateRoute path="/account/orders/:order_id" component={Order} />
              <PrivateRoute
                path="/account/patients/:patient_id/subscription"
                component={PatientSubscription}
              />
              <PrivateRoute path="/account/account-info" component={PatientInfo} />
              <ResetPassword path="/login/forgot-password" typeOfUser="customer" />
              <NewPasswordForm path="/login/forgot-password/reset-form" typeOfUser="customer" />
              <Login path="/login" typeOfUser="customer" location={location} />
              {/*
              comment register functionality
              <Register path="/register" typeOfUser="customer" />
              <RegisterConfirmation path="/registration-confirmation" />
              */}
            </Router>
          </Elements>
        </Layout>
      </PatientContextProvider>
    </OrderContextProvider>
  );
};

export default Customer;
