import React, { useState, useEffect } from 'react';

import Loader from '../../../../loader/Loader';
import CustomTable from '../../../../shared/table/CustomTable';
import DoubleTable from '../../../../shared/table/doubleTable/DoubleTable';
import HeadingTable from '../../../../shared/table/headingTable/HeadingTable';
import { getOrder } from '../../../../../services/requests';
import { infoNames, shippingNames, eyeNames } from './tableNames';
import './Order.scss';

const Order = ({ location }) => {
  const [customer, setCustomer] = useState();
  const [doctor, setDoctor] = useState();
  const [shippingAddress, setShippingAddress] = useState();
  const [eyes, setEyes] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const search = location.pathname.split('/');
    const order_id = search[search.length - 1];

    getOrder(order_id)
      .then((response) => {
        const { customer, doctor, order_items, shipping_address, total_price } =
          response.data.orders;
        setCustomer(customer);
        setDoctor(doctor);
        setShippingAddress(shipping_address);
        setTotalPrice(total_price);
        const tempEyes = order_items.map((eye) => ({
          ...eye.product_variant,
          ...eye.product_variant.product_reference,
        }));

        setEyes(tempEyes);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className="tile-container patient-order">
      <HeadingTable title="Product" product={eyes[0].title} extraLarge />
      <div className="flex-box space-between participants">
        <CustomTable title="Customer" item={customer} names={infoNames} half hideEmpty />
        <CustomTable title="Doctor" item={doctor} names={infoNames} half hideEmpty />
      </div>
      {eyes.length > 1 ? (
        <DoubleTable
          title="Prescription"
          subtitles={['Left Eye (OS)', 'Right Eye (OD)']}
          names={[eyeNames, eyeNames]}
          items={eyes}
          extraLarge
          hideEmpty
        />
      ) : (
        <CustomTable
          title="Prescription"
          item={eyes[0]}
          names={eyeNames}
          extraLarge
          half
          hideEmpty
        />
      )}
      <CustomTable
        title="Shipping Information"
        item={{
          ...shippingAddress,
          full_name: `${shippingAddress.first_name} ${shippingAddress.last_name}`,
        }}
        names={shippingNames}
        extraLarge
        half
      />
      <HeadingTable title="Total Price" product={`$${totalPrice}`} extraLarge />
    </div>
  );
};

export default Order;
