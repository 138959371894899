export const infoNames = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
};

export const eyeNames = {
  base_curve: 'Base Curve',
  diameter: 'Diameter',
  power: 'Power',
  add_power: 'Add Power',
  cylinder: 'Cylinder',
  axis: 'Axis',
  color: 'Color',
  multifocalType: 'Multifocal Type',
};

export const shippingNames = {
  full_name: 'Full Name',
  city: 'City',
  province: 'Province',
  address1: 'Address Street',
  zip: 'ZIP',
};
